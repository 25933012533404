@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Radio+Canada+Big:ital,wght@0,400..700;1,400..700&family=Roboto+Slab:wght@100..900&display=swap');



.font-1 {
    font-family: "DM Sans", sans-serif;
}
.font-2 {
    font-family: "Roboto Slab", serif;
}

.font-3 {
    font-family: "Radio Canada Big", sans-serif;
}

.test {
    place-items: center start;
}

.btn {
    border-radius: 0px;
}
.custom-shape-divider-top-1723572760 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1723572760 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 206px;
}

.custom-shape-divider-top-1723572760 .shape-fill {
    fill: #E5E6E6;
}

.input {
    border-radius: 0px;
}

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }



  .custom-shape-divider-top-1734825100 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1734825100 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
}

.custom-shape-divider-top-1734825100 .shape-fill {
    fill: #181017;
}